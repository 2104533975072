/* ======================= Base ======================= */
:root{
  --main-color: #000000;
  --accent-1: #14213d;
  --accent-2: #e5e5e5;
  --text-2: #fca311;
  --text-1: #ffffff;

}

* {    
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

p {
  width: clamp(25ch, 90%, 75ch);
}

main {
  padding-top: 0.1rem;
}

section {
  margin: 5vw 10vw 3vw;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

h1{
  margin-top: 4rem;
}

h2{
  color: var(--text-2);
}

img{
  max-height: 20em;
  margin: 1rem;
}

::-webkit-scrollbar {
  width: 0;   
}

::-webkit-scrollbar-track {
  background: var(--accent-2);  
}

::-webkit-scrollbar-thumb {
  background-color: var(--accent-1); 
  border: 3px solid var(--accent-2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--main-color);
  border: 0; 
}