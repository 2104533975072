/* ======================= App ======================= */
.App{
  background-color: var(--main-color);
  font-family:Verdana, sans-serif;
  color: var(--text-1);
  line-height: 1.5;
}

.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ======================= Navigation ======================= */
.header{
  width: 100%;
  background-color: var(--accent-1);
  text-align: center;
  position: fixed;
  z-index: 999;
}

.logo{
  color: var(--accent-2);
  font-size: 2em;
  margin-left: 2vw;
}

.navbar{
  background: var(--accent-1);
  position:absolute;
  text-align: left;
  top: 100%;
  left: 0;
  width: 100%;
  transform: scale(1,0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}
 
.toggle-nav{
  display: none;
}

.toggle-nav-label {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}

.toggle-nav-label span,
.toggle-nav-label span::before,
.toggle-nav-label span::after {
  display: block;
  background: var(--text-1);
  height: 2px;
  width: 2em;
  position: relative;
}

.toggle-nav-label span::before,
.toggle-nav-label span::after {
  content:'';
  position: absolute;
}

.toggle-nav-label span::before {
  bottom: 7px;
}

.toggle-nav-label span::after {
  top: 7px;
}

.navbar ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar li{
  margin-bottom: 1em;
  margin-left: 1em;
}
 
.navbar a{
 font-size: 1.1em;
 color: var(--text-1);
 text-decoration: none;
 text-transform: uppercase;
 opacity: 0;
 transition: opacity 150ms ease-in-out;
}

.navbar a:hover{
color: var(--text-2);
}

.toggle-nav:checked ~ .navbar{
  transform: scale(1,1);
  }
  
.toggle-nav:checked ~ .navbar a{
  opacity: 1; 
  transition: opacity 250ms ease-in-out 250ms;
}

@media screen and (min-width: 1100px) {
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar{
    all: unset;
    margin-right: 2vw;
  }

  .navbar ul{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navbar li{
    padding: 0 1rem;
    margin: 0;
  }

  .navbar a{
    opacity: 1;
 }

  .toggle-nav-label{
    display: none;
 }
}

/* ======================= Introduction ======================= */
.intro{
  display: grid;
  justify-items: center;
  text-align: center;
}

.title{
  font-size: clamp(2.5rem, 7vw, 4rem);
  color: var(--accent-2);
}
.subtitle{
  font-size: clamp(2rem, 3vw, 3rem);
  color: var(--text-2);
}

/* ======================= Experience ======================= */
.exper-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;
  padding: 1em;
  width: clamp(20rem, 50%, 35rem);
  background-color: var(--accent-1);
  border-radius: .4rem;
  min-height: clamp(30rem, 40rem, 45rem);
  overflow: hidden;
}

.exper-card:hover{
  box-shadow: 0 0 2rem var(--accent-1);
}

.exper-title{
  text-align: center;
}

.exper-date{
  font-size: .8rem;
  color: var(--text-2);
}

.exper-img{
  display: none;
} 

@media screen and (min-width: 350px) {
  .exper-img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } 
}

/* ======================= Project ======================= */
.proj-card{
  display: grid;
  justify-items: center;
  margin: 1rem;
  padding: 1rem 0.1rem;
  width: clamp(20rem, 23vw, 50rem);
  background-color: var(--accent-1);
  border-radius: .4rem;
  min-height: 17rem;
  position: relative;
}

.proj-detail{
  width: clamp(17rem, 20vw, 20rem);
  min-height: 10rem;
}

.proj-card:hover{
  box-shadow: 0 0 1rem var(--accent-2);
}
/* ======================= Footer ======================= */
.footer{
  display: flex;
  justify-content: space-evenly;
  background-color: var(--accent-1);
  color: var(--text-2);
  font-weight: bold;
  padding: 1rem;
}

.footer p{
  width: 10vw;
}
.footer a{
  width: 10vw;
  text-decoration: none;
  color: var(--text-1);
}
.footer a:hover{
  color: var(--text-2);
}

@media screen and (min-width: 1100px) {
  .footer{
    justify-content: center;
  }
}